/* eslint-disable indent */
import { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  BottomNavigation,
  Collapse,
  ListItemButton,
  Paper,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';
import Logo from '@app/assets/images/logo-mankind.png';
import { NavLink, useLocation } from 'react-router-dom';
import { routeSelector, routeStateActions } from '@app/router/redux';
import { authTokenActions } from '@app/screens/generic/Login/redux';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { RoutesName } from '@app/router/paths';
import { trackEvent } from '@app/utils/analytics';
import {
  EVENT_NAME_LOG_OUT_BUTTON,
  EVENT_NAME_DRAWER_CLOSE,
} from '@app/utils/analytics/constants';
import { RenderChilds } from '@app/components/widgets/RenderChilds';
import { CustomTypography } from '@app/components/widgets/Typography';
import { appSelector } from '@app/store/selectors';


const styles = {
  bottomNavigationListItem: {
    textAlign: 'center',
    backgroundColor: '#f5f8f9',
    display: 'flex',
  },
  bottomNavigationTypography: (theme: any) => {
    return {
      color: theme.typography.leftSideBar.color,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  },
  bottomNavigationListItemIcon: { minWidth: 0, marginRight: 2 },
  typographyFont: {
    fontSize: 12.7,
  },
  textDecorationNone: (theme: any) => {
    return {
      textDecoration: 'none',
      color: theme.typography.leftSideBar.color,
    };
  },
};

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SecondaryListItems = function ({ route, appSettingsAutoNdsData }: { route: any, appSettingsAutoNdsData: any }) {
  const theme = useTheme<any>();
  const location = useLocation();
  const [primaryMenu, setPrimaryMenu] = useState(false);
  const [secondaryMenu, setSecondaryMenu] = useState(
    route?.firstLevel.map(() => {
      return false;
    }),
  );
  return (
    <>
      {route ? (
        <Box paddingLeft={theme.spacing(3)} marginBottom={theme.spacing(1.2)}>
          <Box
            borderRadius={theme.spacing(2)}
            sx={{
              bgcolor:
                route?.webPath &&
                  route?.webPath?.length > 0 &&
                  location.pathname.includes(route?.webPath)
                  ? theme.palette.white.main
                  : theme.backgroundColors.leftSidebar,
            }}>
            <NavLink
              to={route?.webPath || RoutesName.InProgress.path}
              style={styles.textDecorationNone(theme)}>
              <ListItemButton
                onClick={() => {
                  setPrimaryMenu(!primaryMenu);
                }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontWeight: location.pathname.includes(route?.webPath)
                          ? theme.typography.fontWeightBold
                          : theme.typography.fontWeightRegular,
                        fontSize: 12.7,
                      }}>
                      {route?.menuText}
                    </Typography>
                  }
                />

                {route?.firstLevel && route?.firstLevel?.length > 0 && (
                  <RenderChilds
                    condition={primaryMenu}
                    child1={<ExpandLess />}
                    child2={<ExpandMore />}
                  />
                )}
              </ListItemButton>
            </NavLink>

            {route?.firstLevel && route?.firstLevel?.length > 0 && appSettingsAutoNdsData.isActive && (
              <>
                {route?.firstLevel
                  // .filter((routeFirstLevel: any) => routeFirstLevel.webComponent !== 'DCRUnlockRequest') 
                  // DCRUnlockRequest: was removed in case of NDS, UAT observartion wanted it to be there 
                  // Uncomment the filter line in case of removal of the "DCRUnlockRequest" from the Side Bar
                  .map((routeFirstLevel: any, index: any) => {
                    return (
                      <>
                        <Collapse in={primaryMenu} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <NavLink
                              to={
                                routeFirstLevel?.webPath ||
                                RoutesName.InProgress.path
                              }
                              style={styles.textDecorationNone(theme)}>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                  setSecondaryMenu(
                                    secondaryMenu.map(
                                      (menu: any, _index: any) => {
                                        if (_index === index) {
                                          return !menu;
                                        } else {
                                          return menu;
                                        }
                                      },
                                    ),
                                  );
                                }}>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontWeight: location.pathname.includes(
                                          routeFirstLevel?.webPath,
                                        )
                                          ? theme.typography.fontWeightBold
                                          : theme.typography.fontWeightRegular,
                                        fontSize: 12.7,
                                      }}>
                                      {routeFirstLevel?.menuText}
                                    </Typography>
                                  }
                                />

                                {routeFirstLevel?.secondLevel &&
                                  routeFirstLevel?.secondLevel?.length > 0 && (
                                    <RenderChilds
                                      condition={secondaryMenu[index]}
                                      child1={<ExpandLess />}
                                      child2={<ExpandMore />}
                                    />
                                  )}
                              </ListItemButton>
                            </NavLink>
                          </List>

                          {/* thired level start from here */}
                          <>
                            {routeFirstLevel?.secondLevel &&
                              routeFirstLevel?.secondLevel?.length > 0 && (
                                <Box marginLeft={'20px'}>
                                  {routeFirstLevel?.secondLevel.map(
                                    (routeSecondLevel: any) => {
                                      return (
                                        <>
                                          <Collapse
                                            in={secondaryMenu[index]}
                                            timeout="auto"
                                            unmountOnExit>
                                            <List component="div" disablePadding>
                                              <NavLink
                                                to={
                                                  routeSecondLevel?.webPath ||
                                                  RoutesName.InProgress.path
                                                }
                                                style={styles.textDecorationNone(
                                                  theme,
                                                )}>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                  <ListItemText
                                                    primary={
                                                      <Typography
                                                        sx={{
                                                          fontWeight:
                                                            location.pathname.includes(
                                                              routeSecondLevel?.webPath,
                                                            )
                                                              ? theme.typography
                                                                .fontWeightBold
                                                              : theme.typography
                                                                .fontWeightRegular,
                                                          fontSize: 12.7,
                                                        }}>
                                                        {
                                                          routeSecondLevel?.menuText
                                                        }
                                                      </Typography>
                                                    }
                                                  />
                                                </ListItemButton>
                                              </NavLink>
                                            </List>
                                          </Collapse>
                                        </>
                                      );
                                    },
                                  )}
                                </Box>
                              )}
                          </>
                        </Collapse>
                      </>
                    );
                  })}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

const getRouteLinks = (routes: any, appSettingsAutoNdsData: any) => {
  const routeLinks: any = [];
  routes.map((route: any) => {
    routeLinks.push(<SecondaryListItems route={route} appSettingsAutoNdsData={appSettingsAutoNdsData} />);
    return null;
  });

  return routeLinks;
};

export const LeftSideBar = ({ authorized }: { authorized?: boolean }) => {
  const theme = useTheme<any>();
  const location = useLocation();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(routeSelector.getSideBarOpen(), shallowEqual);
  const isRoutesLoading = useSelector(
    routeSelector.getIsRoutesLoading(),
    shallowEqual,
  );
  const appRoutes = useSelector(routeSelector.getAppRoutes(), shallowEqual);
  const appSettingsAutoNdsData = useSelector(appSelector.getAutoNDSData());
  const handleDrawerClose = () => {
    dispatch(routeStateActions.setSideBarOpen(false));
    trackEvent(EVENT_NAME_DRAWER_CLOSE, {});
  };

  const signOut = () => {
    dispatch(authTokenActions.signOut());
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    trackEvent(EVENT_NAME_LOG_OUT_BUTTON, {});

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!isRoutesLoading && (
        <>
          <Drawer
            PaperProps={{
              sx: {
                bgcolor: theme.backgroundColors.leftSidebar,
                position: 'relative',
                height: '100vh',
                paddingBottom: theme.spacing(6.5),
              },
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                border: 0,
              },
            }}
            variant="persistent"
            anchor="left"
            open={sideBarOpen}>
            <DrawerHeader>
              {authorized && (
                <Box>
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: theme.spacing(19) }}
                  />
                </Box>
              )}
              <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />

            {authorized && (
              <>
                <Box
                  paddingLeft={theme.spacing(3)}
                  marginBottom={theme.spacing(1.2)}>
                  <Box
                    borderRadius={theme.spacing(2)}
                    sx={{
                      bgcolor:
                        location.pathname.includes('home') ||
                          location.pathname === '/'
                          ? theme.palette.white.main
                          : theme.backgroundColors.leftSidebar,
                    }}>
                    <NavLink
                      to="/home"
                      style={styles.textDecorationNone(theme)}>
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontWeight:
                                  location.pathname.includes('home') ||
                                    location.pathname === '/'
                                    ? theme.typography.fontWeightBold
                                    : theme.typography.fontWeightRegular,
                                fontSize: 12.7,
                              }}
                            >
                              Home
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </NavLink>
                  </Box>
                </Box>
                {appRoutes && appRoutes.length > 0 && getRouteLinks(appRoutes, appSettingsAutoNdsData)}
              </>
            )}
            <Paper
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                width: drawerWidth,
                cursor: 'pointer',
              }}
              elevation={0}>
              <BottomNavigation showLabels value={0}>
                <ListItem
                  sx={styles.bottomNavigationListItem}
                  onClick={handleClickOpen}>
                  <ListItemText
                    primary={
                      <CustomTypography sx={styles.bottomNavigationTypography}>
                        <>
                          <ListItemIcon
                            sx={styles.bottomNavigationListItemIcon}>
                            <Logout />
                          </ListItemIcon>
                          {'Logout'}
                        </>
                      </CustomTypography>
                    }
                  />
                </ListItem>
              </BottomNavigation>
            </Paper>
          </Drawer>
        </>
      )}

      <Dialog
        sx={{ position: 'absolute', bottom: 350 }}
        open={open}
        onClose={handleClose}>
        <DialogTitle sx={{ color: theme.palette.title.main }}>
          {'Are you sure you want to log off?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={signOut} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
